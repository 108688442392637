<template>
	<v-container fluid>
		<transition name="fade" mode="out-in">
			<router-view> </router-view>
		</transition>
	</v-container>
</template>

<script>
export default {}
</script>

<style>
.container {
	height: 100%;
}
.v-card.home {
	height: 100%;
}
.v-card {
	margin: 0px !important;
}
</style>
